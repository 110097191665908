<template>
  <v-dialog
      v-model="dialog"
      max-width="400px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Новое поле</span>
      </v-card-title>
      <v-card-text>
        <v-text-field placeholder="Введите название" required outlined hide-details v-model="name"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text :disabled="!name" @click.prevent="addField">Добавить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'NewCustomField',
  props: ['value'],
  data() {
    return {
      name: '',
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
  methods: {
    addField() {
      this.dialog = false;
      this.$emit('add', this.name);
    },
  },

};
</script>
