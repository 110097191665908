<template>
  <div class="flex-grow-1">
    <v-stepper v-model="step" vertical elevation="0">
      <Step1 :step="step" :visible-types="visibleTypes" v-model="contacts"/>
      <Step2 :step="step" v-model="columnsData" :contacts="contacts" @next="step++"/>
      <Step3 :step="step" v-model="selectedListForUpload" :disabled="!!targetBase"/>
    </v-stepper>

    <v-btn :loading="isProcess" color="hemp" dark @click="process" class="mt-4" :disabled="!(!isProcess && contacts && selectedListForUpload && columnsData)">Импортировать</v-btn>
  </div>
</template>

<script>
import Step1 from './Steps/Step1/Index.vue';
import Step2 from './Steps/Step2/Index.vue';
import Step3 from './Steps/Step3/Index.vue';
import FieldTypes from './Steps/Step2/Internal/FieldTypes';

export default {
  name: 'ImportContacts',
  components: { Step1, Step2, Step3 },
  props: {
    visibleTypes: {
      type: Number,
      required: false,
    },
    targetBase: Object,
  },
  data() {
    return {
      isProcess: false,
      columnsData: null,
      contacts: null,
      selectedListForUpload: null,
      step: 1,
    };
  },
  created() {
    this.selectedListForUpload = this.targetBase;
  },
  watch: {
    contacts(v) {
      if (v) {
        this.step = 2;
      } else {
        this.columnsData = null;
      }
    },
    targetBase(v) {
      this.selectedListForUpload = v;
    },
  },
  methods: {
    async process() {
      // const message = 'Загрузка большого количества номеров может занять время, список будет увеличиваться по мере обработки номеров, наберитесь терпения!';
      // this.$notify({
      //   text: message,
      //   type: 'warning',
      //   position: 'top center',
      //   duration: -1,
      // });

      this.isProcess = true;
      let phoneKey = null;
      const customKeys = [];

      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const key in this.columnsData.definitionMap) {
        if (this.columnsData.definitionMap[key] === null) {
          continue;
        }

        if (FieldTypes.IS_TYPE(this.columnsData.definitionMap[key], FieldTypes.FIELD_PHONE)) {
          // eslint-disable-next-line radix
          phoneKey = parseInt(key);
          continue;
        }

        // eslint-disable-next-line radix
        customKeys.push(parseInt(key));
      }

      if (phoneKey === null) {
        this.$notify({ text: 'Внутренняя ошибка!', type: 'error' });
        this.isProcess = false;
        return;
      }

      const prepared = {};
      let skip = this.columnsData.firstRowAsHeaders;

      // eslint-disable-next-line no-restricted-syntax
      for (const row of this.contacts.data) {
        if (skip) {
          skip = false;
          continue;
        }

        const e = {};

        // eslint-disable-next-line no-restricted-syntax
        for (const key of customKeys) {
          e[FieldTypes.GET_LABEL(this.columnsData.definitionMap[key])] = row[key];
        }

        prepared[row[phoneKey]] = e;
      }

      try {
        await this.$store.dispatch('IMPORT_NUMBERS_TO_CONTACT_LIST', {
          data: prepared,
          id: this.selectedListForUpload.id,
        });
      } catch (e) {
        this.$notify({ text: 'Не удалось произвести импорт!', type: 'error' });
        return;
      } finally {
        this.isProcess = false;
      }

      this.$notify({ text: 'Номера успешно импортированы!', type: 'success' });

      this.$emit('after-import', this.selectedListForUpload);
    },
  },
};
</script>
