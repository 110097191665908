import XLSX from 'xlsx';
import { readFileAsString, readFileAsUint8Array } from '../helpers';

export default async function (file) {
  let data;
  let typeHandler;
  const fileExt = file?.name.split('.').pop();

  if (fileExt === 'csv') {
    data = await readFileAsString(file);
    typeHandler = 'string';
  } else {
    data = await readFileAsUint8Array(file);
    typeHandler = 'array';
  }

  const workbook = XLSX.read(data, { type: typeHandler, cellText: false, cellDates: true });

  if (!workbook.SheetNames.length) {
    throw new Error('Empty workbooks');
  }

  return XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
    header: 1,
    blankrows: true,
    defval: '',
    raw: false,
    dateNF: fileExt === 'csv' ? 'mm"."dd"."yyyy' : 'dd"."mm"."yyyy', // какой то баг с csv месяц за день, день за месяц воспринимает
  });
}
