<template>
  <div>
    <input-drag-and-drop v-model="file" :accept="allowedMimes">
      <div class="grey--text text-caption mt-2 ml-2">
        Перед импортом файла, сохраните его в кодировке UTF-8.
      </div>
    </input-drag-and-drop>
  </div>
</template>

<script>
import InputDragAndDrop from '../../../../InputDragAndDrop.vue';
import DataImporter from './Internal/DataImporter';

export default {
  name: 'FileImporter',
  props: ['value'],
  components: { InputDragAndDrop },
  data() {
    return {
      file: null,
    };
  },
  computed: {
    allowedMimes() {
      return DataImporter.getInputMimeString();
    },
  },
  watch: {
    file(file) {
      if (file) {
        this.process(file);
      }
    },
  },
  methods: {
    async process(file) {
      try {
        const data = await DataImporter.importFromFile(file);
        this.$emit('input', data);
      } catch (e) {
        this.$notify({ text: 'Файл имеет не верный тип или плохую структуру!', type: 'error' });
      }
    },
  },
};
</script>
