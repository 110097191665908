<template>
  <v-row>
    <v-col cols="12">
      <v-stepper-step :step="2" :complete="allowContinue" :editable="!!contacts && step > 2">
        Настройка полей
        <template v-if="contacts" >
          <small class="mt-2"><strong>Количество полей: {{ importColumnsLength }} импортируем, {{ skipColumnsLength }} игнорируем</strong></small>
          <small class="mt-2" v-if="importedColumnNames"><strong>Импортируемые поля: {{ importedColumnNames }}</strong></small>
        </template>
      </v-stepper-step>

      <v-stepper-content :step="2">
        <template v-if="contacts">
          <Content :import-data="contacts" v-model="contentData"/>
          <v-btn  :disabled="!allowContinue" class="mt-4" dark color="hemp" @click="send">Продолжить</v-btn>
        </template>
      </v-stepper-content>
    </v-col>
  </v-row>
</template>

<script>
import Content from './Components/Content.vue';
import FieldTypes from './Internal/FieldTypes';

export default {
  name: 'SettingsList',
  components: { Content },
  props: {
    value: {
      required: true,
    },
    step: Number,
    contacts: {
      default: null,
    },
  },
  computed: {
    contentData: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
    allowContinue() {
      if (!this.contentData) {
        return false;
      }

      return this.contentData.phoneExists;
    },
    importedColumnNames() {
      if (!this.contentData) {
        return null;
      }

      return this.contentData.definitionMap.reduce((a, e) => {
        if (e !== null) {
          a.push(FieldTypes.GET_LABEL(e));
        }

        return a;
      }, []).join(', ');
    },
    skipColumnsLength() {
      return (this.contacts ? this.contacts.columns : 0) - this.importColumnsLength;
    },
    importColumnsLength() {
      if (!this.contentData) {
        return 0;
      }

      return this.contentData.definitionMap.reduce((a, e) => (e ? ++a : a), 0);
    },
  },
  methods: {
    send() {
      this.$emit('next');
    },
  },
};
</script>
