function getObj(type, append = null) {
  return {
    type,
    append,
  };
}

export default {
  FIELD_PHONE: 0x10,
  FIELD_CUSTOM: 0x01,
  NEW_PHONE() {
    return getObj(this.FIELD_PHONE);
  },
  NEW_CUSTOM(name) {
    return getObj(this.FIELD_CUSTOM, name);
  },
  IS_TYPE(obj, type) {
    return obj !== null && obj.type === type;
  },
  GET_LABEL(obj) {
    if (!obj) {
      return null;
    }

    return obj.type === this.FIELD_PHONE
      ? 'Телефон'
      : obj.append;
  },
};
