export function getMaxCellInRows(rows) {
  let max = 0;

  // eslint-disable-next-line no-restricted-syntax
  for (const row of rows) {
    if (row.length > max) {
      max = row;
    }
  }

  return max;
}

export function fillUpRows(rows, count) {
  return rows
    .filter(row => row.some(el => el || el === 0))
    .map(row => {
      const l = row.length;
      if (l < count) {
        for (let s = l; l < count; s++) {
          row[s] = '';
        }
      }
      return row;
    });
}

export function readFileAsUint8Array(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = reject;
    reader.onload = function (e) {
      resolve(new Uint8Array(e.target.result));
    };

    reader.readAsArrayBuffer(file);
  });
}

export function readFileAsString(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = reject;
    reader.onload = function (e) {
      resolve(e.target.result);
    };

    reader.readAsText(file);
  });
}
