<template>
  <v-dialog v-model="isOpen" persistent max-width="400px">
    <v-form>
      <v-card>
        <v-card-title>
          <span class="text-h5">Список</span>
        </v-card-title>
        <v-card-text>
          <v-text-field placeholder="Введите название*" :rules="[rules.required]" outlined dense v-model="name"/>
          <v-textarea placeholder="Комментарий" outlined hide-details v-model="comment" dense/>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text type="button" @click="$emit('close')">Отменить</v-btn>
          <v-btn dark color="hemp" :disabled="!name" text @click="createBase">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DialogCreateBase',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: '',
      comment: '',
      rules: {
        required: value => !!value || 'Поле обязательное для заполнения',
      },
    };
  },
  watch: {
    isOpen(val) {
      if (!val) {
        this.name = '';
        this.comment = '';
      }
    },
  },
  methods: {
    ...mapActions(['CREATE_BASE']),
    createBase() {
      this.CREATE_BASE({ name: this.name, comment: this.comment })
        .then(base => {
          this.$emit('new', base);
          this.$emit('close');
          this.$notify({ text: `Список «${this.name}» создан`, type: 'success' });
        })
        .catch(() => { this.$notify({ text: 'Не удалось создать список', type: 'error' }); });
    },
  },
};
</script>
