import { getMaxCellInRows, fillUpRows } from './helpers';
import TextProcessor from './Processors/TextProcessor';
import TxtProcessor from './Processors/TxtProcessor';
import TableProcessor from './Processors/TableProcessor';

class DataImporter {
  #map = {};

  #mimes= {};

  getInputMimeString() {
    return Object.keys(this.#map).join(', ');
  }

  registerMime(mime, fromExt = null, processor) {
    this.#mimes[mime] = processor;
    this.#map[fromExt ? `.${fromExt}` : mime] = mime;
  }

  async importFromFile(file) {
    const mime = this.#findAllowedMime(file);

    if (!mime) {
      throw new Error('Bad file type!');
    }

    const data = await this.#mimes[mime](file);

    return this.#returnNormalizedData(data);
  }

  // eslint-disable-next-line class-methods-use-this
  #returnNormalizedData(data) {
    const maxCellsInRow = getMaxCellInRows(data);

    if (!maxCellsInRow) {
      throw new Error('Bad file content!');
    }

    data = fillUpRows(data, maxCellsInRow);

    return {
      rows: data.length,
      columns: data[0].length,
      data,
    };
  }

  importFromString(data) {
    return this.#returnNormalizedData(TextProcessor(data));
  }

  // eslint-disable-next-line no-dupe-class-members
  #findAllowedMime(file) {
    return this.#map[file.type || `.${file.name.split('.').pop()}`] || null;
  }
}

const importer = new DataImporter();

importer.registerMime('text/csv', null, TableProcessor);
importer.registerMime('text/plain', null, TxtProcessor);
importer.registerMime('application/vnd.ms-excel', null, TableProcessor);
importer.registerMime('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', null, TableProcessor);

export default importer;
