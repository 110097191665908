<template>
  <div>
    <v-switch
        v-if="importData.rows > 1"
        v-model="firstRowAsHeaders"
        label="Первая строка, как заголовок"
    />

    <div v-show="!(value && value.phoneExists)" class="red--text text-caption mb-4 ml-2">
      Укажите у нужного набора данных поле "Телефон".
    </div>

    <v-row>
      <v-col cols="12" sm="6" md="3" v-for="(_, index) in columnsLength" :key="index">
        <Column v-model="definitionMap[index]" :items="columnItems(index)"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Column from './Column.vue';
import FieldTypes from '../Internal/FieldTypes';
import { isPhoneNumber } from '../Internal/checkerFieldType';

const MAX_EXAMPLE_ROWS = 10;

export default {
  name: 'Content',
  components: { Column },
  props: {
    value: {
      required: true,
    },
    importData: {
      type: Object,
    },
  },
  data() {
    return {
      firstRowAsHeaders: false,
      definitionMap: {},
      mapWatchers: {},
    };
  },
  watch: {
    importData() {
      this.$emit('input', null);
      this.firstRowAsHeaders = false;
      this.unsetWatchers();
      this.init();
    },
    firstRowAsHeaders(v) {
      if (v) {
        this.definitionMap = this.importData.data[0].map((header, i) => (
          this.definitionMap[i] === null
            ? header.toLowerCase() === 'телефон'
              ? FieldTypes.NEW_PHONE()
              : FieldTypes.NEW_CUSTOM(header)
            : this.definitionMap[i]
        ));
      } else {
        this.definitionMap = this.definitionMap.map(col => (FieldTypes.IS_TYPE(col, FieldTypes.FIELD_PHONE) ? col : null));
      }

      this.sendObj();
    },
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    this.unsetWatchers();
  },
  methods: {
    init() {
      let newMap = {};

      if (this.importData) {
        newMap = Array(this.columnsLength).fill(null);
      }

      this.definitionMap = newMap;

      for (let i = 0; i < this.importData.columns; i++) {
        this.setWatchMapItem(i);
      }

      this.autoSetPhoneColumn();
    },
    autoSetPhoneColumn() {
      // возьмем 2 строки, т.к. первая может быть заголовочной
      const [firstRow, secondRow] = this.importData.data;

      // определим номер колонки в ячейке которой есть номер телефона
      for (let i = 0; i < this.columnsLength; i++) {
        if (isPhoneNumber(firstRow[i]) || (secondRow && isPhoneNumber(secondRow[i]))) {
          this.definitionMap = Array(this.columnsLength).fill(null);

          this.definitionMap[i] = FieldTypes.NEW_PHONE();

          break;
        }
      }
    },
    setWatchMapItem(key) {
      this.mapWatchers[key] = this.$watch(`definitionMap.${key}`, this.watchHandler(key));
    },
    unsetWatchMapItem(key) {
      this.mapWatchers[key]();
      this.mapWatchers[key] = null;
    },
    unsetWatchers() {
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const key in this.mapWatchers) {
        this.mapWatchers[key]();
      }
    },
    watchHandler(i) {
      return val => {
        if (!FieldTypes.IS_TYPE(val, FieldTypes.FIELD_PHONE)) {
          return this.sendObj();
        }

        const key = this.phoneFieldKeyFind(i);

        if (key !== null) {
          this.unsetWatchMapItem(key);
          this.definitionMap[key] = null;
          this.setWatchMapItem(key);
        }

        return this.sendObj();
      };
    },
    sendObj() {
      this.$emit('input', {
        definitionMap: Object.values(this.definitionMap),
        firstRowAsHeaders: this.firstRowAsHeaders,
        phoneExists: this.phoneFieldKeyFind(null) !== null,
      });
    },
    phoneFieldKeyFind(exclude) {
      for (let i = 0; i < this.importData.columns; i++) {
        if (FieldTypes.IS_TYPE(this.definitionMap[i], FieldTypes.FIELD_PHONE) && i !== exclude) {
          return i;
        }
      }

      return null;
    },
    columnItems(index) {
      const items = [];

      let s = 0;
      let e = this.importData.rows > MAX_EXAMPLE_ROWS ? MAX_EXAMPLE_ROWS : this.importData.rows;

      if (this.firstRowAsHeaders) {
        s = 1;

        if (this.importData.rows > MAX_EXAMPLE_ROWS) {
          e++;
        }
      }

      for (let i = s; i < e; i++) {
        items.push(this.importData.data[i][index]);
      }

      return items;
    },
  },
  computed: {
    columnsLength() {
      return this.importData.columns;
    },
  },
};
</script>
