<template>
  <v-row>
    <v-col cols="12">
      <v-stepper-step :step="3" :complete="!!model" :editable="step > 3">
        Сохранение контактов
      </v-stepper-step>

      <v-stepper-content :step="3">
        <v-select
          ref="select"
          placeholder="Выберите список"
          outlined
          hide-details
          :items="BASES"
          return-object
          item-text="name"
          item-value="id"
          :disabled="disabled"
          v-model="model"
        >
          <template v-slot:prepend-item>
            <v-list-item link @click="dialogCreateBase = true">
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon left>mdi-plus</v-icon>Создать новую базу
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <DialogCreateBase
              ref="DialogCreateBase"
              :is-open="dialogCreateBase"
              @new="onNew"
              @close="dialogCreateBase = false"
            />

            <v-divider class="mt-2"></v-divider>

          </template>
        </v-select>
      </v-stepper-content>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import DialogCreateBase from '../../../dialogs/DialogCreateBase.vue';

export default {
  name: 'SelectContactListUpload',
  components: { DialogCreateBase },
  props: {
    step: Number,
    value: {
      type: Object,
      validator: prop => typeof prop === 'object' || prop === null,
    },
    disabled: Boolean,
  },
  data() {
    return {
      dialogCreateBase: false,
    };
  },
  computed: {
    ...mapGetters(['BASES']),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onNew(base) {
      this.$refs.select.blur();
      this.$emit('input', base);
    },
  },
};
</script>
