<template>
  <v-row>
    <v-col cols="12">
      <v-stepper-step :step="1" :complete="step > 1" editable>
        Загрузка контактов
        <small v-if="textUploadList" class="mt-1"><strong>{{ textUploadList }}</strong></small>
      </v-stepper-step>

      <v-stepper-content :step="1">
        <v-tabs grow :value="typeUpload">
          <v-tab v-if="allowFile"  @click="typeUpload = fileType">Из файла</v-tab>
          <v-tab v-if="allowText" @click="typeUpload = textType">Вручную</v-tab>

          <v-tabs-items v-model="typeUpload">
            <v-tab-item class="mt-3 pa-2" :value="fileType" v-if="allowFile">
              <FileImporter v-model="data"/>
            </v-tab-item>

            <v-tab-item class="mt-3 pa-2" :value="textType" v-if="allowText">
              <TextImporter v-model="data"/>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-stepper-content>
    </v-col>
  </v-row>
</template>

<script>
import types from './Importers/Internal/TypeImporters';
import FileImporter from './Importers/FileImporter.vue';
import TextImporter from './Importers/TextImporter.vue';

export default {
  name: 'SelectContacts',
  components: { TextImporter, FileImporter },
  props: {
    visibleTypes: {
      type: Number,
      default: types.TYPE_TEXT | types.TYPE_FILE,
    },
    step: Number,
    value: {
      required: true,
    },
  },
  created() {
    this.typeUpload = this.allowFile ? this.fileType : this.textType;
  },
  data() {
    return {
      typeUpload: null,
      selectedList: null,
    };
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
    fileType() {
      return types.TYPE_FILE;
    },
    textType() {
      return types.TYPE_TEXT;
    },
    allowText() {
      return this.visibleTypes & types.TYPE_TEXT;
    },
    allowFile() {
      return this.visibleTypes & types.TYPE_FILE;
    },
    textUploadList() {
      if (this.typeUpload === null) {
        return '';
      }

      if (this.typeUpload === types.TYPE_TEXT) {
        return 'Вручную';
      }

      if (this.typeUpload === types.TYPE_FILE) {
        return 'Из файла';
      }

      return 'Из списка';
    },
  },
};
</script>
