<template>
  <div>
    <NewCustomField v-model="dialog" @add="addCustomField"/>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn block :color="!selected ? 'grey' : 'primary'" outlined v-bind="attrs" v-on="on" class="btn-field">
          <small>{{ label }}</small>
          <v-icon right class="ml-auto">mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item link dense :disabled="!selected" @click="flush">
          <v-list-item-title>
            <v-icon small>mdi-close-circle-outline</v-icon>
            Не импортировать
          </v-list-item-title>
        </v-list-item>
        <v-list-item link dense :disabled="isPhone" @click="setAsPhone">
          <v-list-item-title>
            <v-icon small>mdi-phone</v-icon>
            Телефон
          </v-list-item-title>
        </v-list-item>
        <v-list-item link dense :disabled="isCustom" @click="dialog = true">
          <v-list-item-title>
            <v-icon small>mdi-pencil</v-icon>
            Произвольное поле
          </v-list-item-title>
        </v-list-item>
        <NewCustomField :disabled="isCustom" @input="addCustomField"/>
      </v-list>
    </v-menu>
    <div class="example-column">
      <div v-for="(item, key) in items" :key="key">
        <span>{{item}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import FieldTypes from '../Internal/FieldTypes';
import NewCustomField from './NewCustomField.vue';

export default {
  name: 'Column',
  components: { NewCustomField },
  props: {
    items: {
      type: Array,
      required: true,
    },
    value: {},
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    addCustomField(name) {
      this.$emit('input', FieldTypes.NEW_CUSTOM(name));
    },
    flush() {
      this.$emit('input', null);
    },
    setAsPhone() {
      this.$emit('input', FieldTypes.NEW_PHONE());
    },
  },
  computed: {
    isPhone() {
      return FieldTypes.IS_TYPE(this.value, FieldTypes.FIELD_PHONE);
    },
    isCustom() {
      return FieldTypes.IS_TYPE(this.value, FieldTypes.FIELD_CUSTOM);
    },
    selected() {
      return this.value !== null;
    },
    label() {
      return FieldTypes.GET_LABEL(this.value) || 'Не импортировать';
    },
  },
};
</script>

<style scoped lang="scss">
.example-column{
  margin-top: 20px;
  border: 1px solid #689f38;
  border-radius: 3px;

  div{
    padding: 0 10px;
    display: flex;
    height: 30px;
    align-items: center;

    span{
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
