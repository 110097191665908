<template>
  <v-row no-gutters>
    <v-sheet
      :color="isHighlight ? ($vuetify.theme.dark ? 'grey darken-3' : 'light-green lighten-5') : ($vuetify.theme.dark ? 'grey darken-4' : 'white')"
      rounded
      width="100%"
      height="200"
      ref="uploader"
      :class="['v-file-upload', 'd-flex', 'align-center', 'justify-center', { 'highlight': isHighlight }]"
      @dragenter.prevent.stop="highlight"
      @dragover.prevent.stop="highlight"
      @dragleave.prevent.stop="unhighlight"
      @drop.prevent.stop="dropFiles"
      @input.prevent.stop="inputFiles"
      tag="label"
    >
      <input type="file" multiple :accept="accept">

      <div v-if="value" class="v-file-upload__file d-flex flex-column">
        <v-icon x-large color="primary" class="mb-2">mdi-file-document</v-icon>
        <div>
          {{ value.name }} <span v-if="fileSize">({{ fileSize }})</span>
        </div>
        <a href="javascript:;" class="text-decoration-none"><v-icon left color="primary">mdi-reload</v-icon>Загрузить другой файл</a>
      </div>

      <div v-else class="v-file-upload__unhighlight d-flex flex-column align-center justify-center">
        <slot name="support-format-text">Поддерживаются форматы: .xlsx .xls .csv</slot>
        <span>
          <slot name="default-text">Перетащите файл со списком контактов сюда</slot>
        </span>
        <sub>или</sub>
        <v-btn class="mt-4">Выберите файл</v-btn>
        <span>
        </span>
      </div>

      <div class="v-file-upload__highlight"><span>Отпустите кнопку мыши что-бы прикрепить файл</span></div>
    </v-sheet>

    <slot />
  </v-row>
</template>

<script>
export default {
  name: 'InputDragAndDrop',
  props: {
    accept: String,
    value: [File, Object],
  },
  data() {
    return {
      isHighlight: false,
    };
  },
  computed: {
    fileSize() {
      if (!this.value?.size) return '';

      const Kb = this.value.size / 1024;
      let size = `${Kb.toFixed(2)} Кб`;

      if (Kb > 500) {
        size = `${(Kb / 1024).toFixed(2)} Мб`;
      }

      return size;
    },
  },
  methods: {
    highlight() {
      this.isHighlight = true;
    },
    unhighlight() {
      this.isHighlight = false;
    },
    dropFiles(event) {
      this.isHighlight = false;
      this.$emit('input', event.dataTransfer.files[0]);
      this.$emit('add');
    },
    inputFiles(event) {
      this.$emit('input', event.target.files[0]);
      this.$emit('add');
    },
  },
};
</script>

<style scoped lang="scss">
.v-file-upload {
  position: relative;
  transition: 0.15s ease-in-out;
  cursor: pointer;
  border: 1px solid rgba(0,0,0,.38) !important;

  input[type="file"] {
    visibility: hidden;
    position: absolute;
  }

  &__unhighlight,
  &__highlight,
  &__file{
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  &__unhighlight,
  &__highlight,
  &__file a {
    pointer-events: none;
  }

  &__unhighlight {
    visibility: visible;

    sub {
      font-size: 100%;
      line-height: 1.2;
      top: 0;
      bottom: 0;
    }
  }

  &__highlight {
    visibility: hidden;
  }

  &.highlight {
    background: #E0E0E0;

    .v-file-upload__unhighlight,
    .v-file-upload__file {
      visibility: hidden;
    }

    .v-file-upload__highlight {
      visibility: visible;
    }
  }
}
</style>
