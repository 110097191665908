<template>
  <div>
    <div class="mb-2 text-caption">
      Введите данные в следующем формате
      <br>
      <strong>Телефон, Имя, Город, Дата, ...</strong>
      <br>
      <strong>Телефон, Имя, Город, , ...</strong>
      <br>
      Данные вводятся через запятую. Размещайте каждый контакт на отдельной строчке
    </div>
    <v-textarea
        outlined
        :placeholder="'81111111111, Иван, Москва, 01.01.2020\n81111111111, Иван, Москва,'"
        class="text-importer-textarea"
        v-model="text"
    />
    <v-btn :disabled="!text" class="mt-4" dark color="hemp" @click="attempt">Продолжить</v-btn>
  </div>
</template>

<script>
import DataImporter from './Internal/DataImporter';

export default {
  name: 'TextImporter',
  props: ['value'],
  data() {
    return {
      text: '',
    };
  },
  methods: {
    attempt() {
      try {
        const data = DataImporter.importFromString(this.text);

        this.$emit('input', data);
      } catch (e) {
        this.$notify({ text: 'Текст имеет не верную структуру!', type: 'error' });
      }
    },
  },
};
</script>

<style>
.text-importer-textarea textarea {
  min-height: 195px;
}
</style>
